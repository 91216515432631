import CharMap from "../../charMap";
import Strip from "../../strip";
import Banner from "../../banner";
import './src/style.scss';
import { useEffect, useState } from "react";
import { listHistoryData } from "../../../api/api";
import { formatTimeTwo } from "../../../utils/utils";
import * as echarts from 'echarts';
function Monitor({ pmdata = [] }) {
    useEffect(() => {
        var chartDom = document.getElementById('monitor');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
            legend: {
                icon: 'none',//icon为圆角矩形
                x: 'right',
                y: '-1%',
                textStyle: {
                    color: '#fff',
                    fontSize: 14
                },
            },
            grid: {
                x: "6%",//x 偏移量
                y: "13%", // y 偏移量
                width: "100%", // 宽度
                height: "80%"// 高度
            },
            axisLabel: {
                show: true,
                color: '#99B3C8',
                fontSize: 12
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: pmdata.map((value: any) => { return value.time }),
                textStyle: {
                    color: '#fff',
                    fontSize: 16
                }
            },
            yAxis: {
                type: 'value',
                splitNumber: 4,
                splitLine: {
                    show: true,
                    lineStyle: {
                        width: 1.5,
                        color: ['rgba(153,179,200,0.5)'],
                        type: [10, 8],
                        dashOffset: 3,
                    }
                }
            },
            series: [
                {
                    name: 'PM2.5',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: {
                        color: '#F1FF9D'
                    },
                    data: pmdata.map((value: any) => { return value.temStr }),
                },
                {
                    name: 'PM10',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: {
                        color: '#FF7272'
                    },
                    data: pmdata.map((value: any) => { return value.humStr })
                }
            ]
        };
        option && myChart.setOption(option);
    }, [pmdata])
    return (<div id="monitor"></div>)
}
function Energy({ ion = [] }) {
    useEffect(() => {
        var chartDom = document.getElementById('energy');
        var myChart = echarts.init(chartDom);
        const option = {
            backgroundColor: '',
            axisLabel: {
                show: true,
                color: '#99B3C8',
                fontSize: 12
            },
            grid: {
                x: "10%",//x 偏移量
                y: "4%", // y 偏移量
                width: "86%", // 宽度
                height: "80%"// 高度
            },
            legend: {
                icon: 'roundRect',//icon为圆角矩形
                x: 'center',
                y: 'bottom',
                textStyle: {//图例文字的样式
                    color: '#fff',
                    fontSize: 16
                },

            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ion.map((value: any) => { return value.time }),
                splitLine: {
                    show: false    //去掉网格线
                }
            },
            yAxis: {
                splitNumber: 3,
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        width: 1.5,
                        color: ['rgba(153,179,200,0.5)'],
                        type: [10, 8],
                        dashOffset: 5,
                    }
                }
            },
            series: [
                {
                    smooth: true,
                    symbol: 'none',
                    data: ion.map((value: any) => { return value.temStr }),
                    type: 'line',
                    areaStyle: {
                        normal: {
                            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#3183CC'

                            }, {
                                offset: 1,
                                color: 'rgba(216,216,216,0)'
                            }])

                        }
                    }
                }
            ]
        };
        option && myChart.setOption(option);
    }, [ion])
    return (<div className="speed" id="energy"></div>)
}
function CulumnModdle() {
    let [pmdata, setPmdata] = useState();
    let [ion, setIon]: any = useState();
    const [ num,setNum ] = useState(1);
    const _listHistoryData = async (id?: any) => {
        try {
            let res = await listHistoryData({ nodeId: id });
            res.data.forEach((item: any) => {
                item.time = formatTimeTwo(item.recordTime, 'h:m');
            })
            if (id == 13) {
                setPmdata(pmdata = res.data);
            }
            if (id == 31) {
                setIon(ion = res.data);
            }
            setTimeout(()=>{
                setNum(num+1);
            },5000)
        } catch (error) {

        }
    }
    useEffect(() => {
        _listHistoryData(13);
        _listHistoryData(31);
        setTimeout(() => {
            _listHistoryData(13);
            _listHistoryData(31);
        }, 5000)
    }, [])
    return (
        <div className="column">
            {/* 地图 */}
            <div className="column__pole">
                <img className="column__landLevel" src={require('../../../static/image/landLevel.png')}></img>
                <img className="column__wifi" src={require('../../../static/image/wifi.gif')}></img>
                <img className="column__wind_shape" src={require('../../../static/image/wind_shape.gif')}></img>
                <img className="column__sun" src={require('../../../static/image/sun.png')}></img>
                <img className="column__wrj" src={require('../../../static/image/wrj.png')}></img>
            </div>
            <div className="column__conten">
                <div className="column__box">
                    <Strip width="416px"><span>环境监测</span></Strip>
                    <Monitor pmdata={pmdata} />
                </div>
                <div className="column__box">
                    <Strip width="416px"><span>负氧离子</span></Strip>
                    <Energy ion={ion} />
                </div>
            </div>
        </div>
    )
}

export default CulumnModdle;