import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Large from './page/large/index';
function App() {
  return (
    <Large />
  );
}

export default App;
