import { useEffect, useState } from "react";
import './src/style.scss';
function Pressure(props: any) {
    const { plan } = props;
    const [sum, setsum]: Array<any> = useState([{}]);
    const [planNumber,setplanNumber] = useState('0');
   
    useEffect(() => {
        if (sum.length > 30) {
            return
        }
        setTimeout(()=>{
            setplanNumber(plan);
        },500)
        setsum([...sum, []]);
    }, [sum])
    return (
        <div className="pressure">
            <div className="pressure__schedule">
                {
                    sum.map((item: any, index: number) => {
                        return <div key={index} className={Math.ceil(parseFloat(planNumber)/3.3) >index? 'pressure__schedule-s' : 'pressure__schedule--s'}></div>
                    })
                }
            </div>
            <div className="pressure__limit"></div>
            <div className="pressure__enter"  style={{
                        left:`calc(${planNumber}% - ${planNumber.length*6.5}px)`
                    }} >
                {/* 进度 */}
                <div>
                    <div className="pressure__enter--icon">
                        <img src={require('../../static/image/angle.png')}></img>
                    </div>
                    <span>{plan}</span>
                </div>
                <span>kpa</span>
            </div>
        </div>
    )
}
export default Pressure;