import './src/style.scss';
import CulumnLeft from "./column-left";
import CulumnModdle from "./column-moddle";
import CulumnRight from "./column-right";
function Sections(){
    return(
        <div className="sections">
            <CulumnLeft/>
            <CulumnModdle/>
            <CulumnRight/>
        </div>
    )
}
export default Sections;