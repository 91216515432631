import React, { useEffect, useState } from "react";
interface SizeProps {
  width: number;
  height: number;
}
export const useGlobalSize= (props:SizeProps) => {
  const getScaleHandler = (prpos: SizeProps) => {
    const { width, height } = prpos;
    let w = window.innerWidth / width;
    let h = window.innerHeight / height;
    return w < h ? w : h;
  };
  const { width, height } = props;
  const [scale, setScale] = useState<number>(getScaleHandler({ width, height }));
  console.log(scale);
  const setScaleHandler = () => {
    setTimeout(() => {
      let scale = getScaleHandler({ width, height })
      setScale(scale)
    }, 50)
  }
  useEffect(() => {
    window.addEventListener('resize', setScaleHandler)
  }, []);
  return{
    scale,
    setScaleHandler,
  }
};
