import Strip from "../../strip";
import Banner from "../../banner";
import './src/style.scss';
import * as echarts from 'echarts';
import { useEffect, useState } from "react";
import { getDeviceAllInfo, listHistoryData } from '../../../api/api';
import { formatTimeTwo } from '../../../utils/utils';

/**
 * @description 风速
 */
function Speed({ wind = [] }) {
    useEffect(() => {
        var chartDom = document.getElementById('speeds');
        var myChart = echarts.init(chartDom);
        const option = {
            backgroundColor: '',
            axisLabel: {
                show: true,
                color: '#99B3C8',
                fontSize: 12
            },
            grid: {
                x: "6%",//x 偏移量
                y: "5%", // y 偏移量
                width: "100%", // 宽度
                height: "80%"// 高度
            },
            legend: {
                icon: 'roundRect',//icon为圆角矩形
                x: 'center',
                y: 'bottom',
                textStyle: {//图例文字的样式
                    color: '#fff',
                    fontSize: 16
                },
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: wind.map((val: any) => { return val.time }),
                splitLine: {
                    show: false    //去掉网格线
                }
            },
            yAxis: {
                type: 'value',
                splitNumber: 4,
                splitLine: {
                    show: true,
                    lineStyle: {
                        width: 1.5,
                        color: ['rgba(153,179,200,0.5)'],
                        type: [10, 8],
                        dashOffset: 5,
                    }
                }
            },
            series: [
                {

                    data: wind.map((value: any) => { return value.humStr }),
                    type: 'line',
                    areaStyle: {
                        normal: {
                            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(10,71,118,0.71)'
                            }, {
                                offset: 1,
                                color: 'rgba(10,71,118,0)'
                            }])

                        }
                    },
                    lineStyle: {
                        normal: {
                            color: '#3F91D8'
                        }
                    },
                    symbol: 'image://https://mp-ecee46d9-d011-49b7-8a4b-c48aa3a08eab.cdn.bspapp.com/2023/07/21/切图 25@2x (1).png',
                    symbolSize: 20,
                    smooth: 0.1,
                    axisLabel: {
                        rotate: 40 // 倾斜40度
                    },
                    itemStyle: {
                        borderColor: '#31F7F9'
                    },
                    color: '#31F7F9'
                }
            ]
        };
        option && myChart.setOption(option);
    }, [wind])
    return (
        <div className="speed" id="speeds"></div>
    )
}

/**
 * 降水
 */
function Rain({ rain = [] }) {
    useEffect(() => {
        var chartDom = document.getElementById('rain');
        var myChart = echarts.init(chartDom);
        const option = {
            axisLabel: {
                show: true,
                color: '#99B3C8',
                fontSize: 12
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                x: "0%",//x 偏移量
                y: "5%", // y 偏移量
                width: "98%", // 宽度
                height: "90%",// 高度
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: rain.map((value: any) => { return value.time }),
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: {
                splitLine: {
                    show: true,
                    lineStyle: {
                        width: 1.5,
                        color: ['rgba(153,179,200,0.5)'],
                        type: [10, 8],
                        dashOffset: 5,
                    }
                }
            },
            series: [
                {
                    name: '降水',
                    type: 'bar',
                    barWidth: '12px',
                    data: rain.map((value: any) => { return value.temStr }),
                    itemStyle: {
                        normal: {
                            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(97,215,215,1)'
                            }, {
                                offset: 1,
                                color: 'rgba(97,215,215,0.01)'
                            }])

                        }
                    }
                }
            ]
        };

        option && myChart.setOption(option);
    }, [rain])
    return (
        <div className="speed" id="rain"></div>
    )
}

/**
 * energy 能见度
 */
function Energy({ ion = [] }) {
    useEffect(() => {
        var chartDom = document.getElementById('energy');
        var myChart = echarts.init(chartDom);
        const option = {
            backgroundColor: '',
            axisLabel: {
                show: true,
                color: '#99B3C8',
                fontSize: 12
            },
            grid: {
                x: "7%",//x 偏移量
                y: "5%", // y 偏移量
                width: "88%", // 宽度
                height: "80%"// 高度
            },
            legend: {
                icon: 'roundRect',//icon为圆角矩形
                x: 'center',
                y: 'bottom',
                textStyle: {//图例文字的样式
                    color: '#fff',
                    fontSize: 16
                },

            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ion.map((value: any) => { return value.time }),
                splitLine: {
                    show: false    //去掉网格线
                }
            },
            yAxis: {
                splitNumber: 3,
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        width: 1.5,
                        color: ['rgba(153,179,200,0.5)'],
                        type: [10, 8],
                        dashOffset: 5,
                    }
                }
            },
            series: [
                {
                    smooth: true,
                    symbol: 'none',
                    data: ion.map((value: any) => { return value.temStr }),
                    type: 'line',
                    areaStyle: {
                        normal: {
                            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#3183CC'

                            }, {
                                offset: 1,
                                color: 'rgba(216,216,216,0)'
                            }])

                        }
                    }
                }
            ]
        };
        option && myChart.setOption(option);
    }, [ion])
    return (<div className="speed" id="energy"></div>)
}
/**
 * @description 进行中
 */
function Proceed(props: any) {
    let { light,humName,rains } = props;
    useEffect(()=>{
    },[rains])
    return (
        <div className="proceed">
            <p className="proceed__title">正在进行物联状态</p>
            <div className="proceed__box">
                <div className="proceed__left">
                    <div className="proceed__left--icon">
                        <img src={require('../../../static/image/sj.png')}></img>
                    </div>
                    <div className="proceed__left--conten">
                        <p>噪声</p>
                        <span>{rains?rains:'0'}dB</span>
                    </div>
                </div>
                <div className="proceed__conten">
                    <p>风向</p>
                    <span>{humName ? humName : '风向'}</span>
                </div>
                <div className="proceed__right">
                    <div className="proceed__right--conten">
                        <p>光照</p>
                        <span>{light?light:''}Lux</span>
                    </div>
                    <div className="proceed__right--icon">
                        <img src={require('../../../static/image/ph.png')}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}
/**
 * 
 * @returns 右边数据
 */
function CulumnRight() {
    // 风速风向ID: 1 降水ID:20 PM2.5ID:13 负氧离子ID:31；
    // 风速
    let [wind, setWind]: any = useState();
    // 降水
    let [rain, setrain]: any = useState();
    let [ion, setIon]: any = useState();
    const [num, setNum] = useState(1);
    const _listHistoryData = async (id: any) => {
        try {
            let res = await listHistoryData({ nodeId: id });
            res.data.forEach((item: any) => {
                item.time = formatTimeTwo(item.recordTime, 'h:m');
            })
            if (id == 1) {
                // 风速
                setWind(wind = res.data);
            }
            if (id == 20) {
                // 降水
                setrain(rain = res.data);
            }
            if (id == 31) {
                setIon(ion = res.data);
            }
            setTimeout(() => {
                setNum(num + 1);
            }, 5000)
        } catch (error) {

        }
    }
    let [humName, sethumName]: any = useState();
    let [light, setlight]: any = useState();
    let [rains, setrains]: any = useState();
    const _getDeviceAllInfo = async () => {
        try {
            let res = await getDeviceAllInfo();
            let data = res.data.data[0].data;
            sethumName(humName=data[1].temValueStr);
            setlight(light=data[6].temValueStr);
            setrains(rains=data[3].humValueStr);
        } catch (error) {

        }
    }
    useEffect(() => {
        _listHistoryData(1);
        _listHistoryData(20);
        _listHistoryData(31);
        _getDeviceAllInfo();
    }, [])
    return (
        <div className="column">
            <Strip><span>精细化预报</span></Strip>
            <Banner><span>风速风向检测</span></Banner>
            <Speed wind={wind} />
            <Banner><span>降水</span></Banner>
            <Rain rain={rain} />
            {/* <Banner><span>负氧离子</span></Banner> */}
            {/* <Energy ion={ion}/> */}
            <Strip><span>其他物联状态</span></Strip>
            <Proceed humName={humName ? humName :'东风'} light={light?light:'0.0'} rains={rains?rains:'0.0'} />
        </div>
    )
}

export default CulumnRight;
