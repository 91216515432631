import React, { useEffect } from "react"
import './src/large.scss';
import { useGlobalSize } from '../../hooks/useGlobalSize';
import Header from "../../components/header";
import Sections from "../../components/sections";
const Large = (e: any) => {
    const wh = {
        width: 1920,
        height: 1080
    }
    const { scale } = useGlobalSize(wh);
    return (
        <div className='large'
            style={{
                transform: `scale(${scale}) translate(-50%,-50%)`,
                WebkitTransform: `scale(${scale}) translate(-50%,-50%)`,
                width: wh.width,
                height: wh.height
            }}
        >
            <Header/>
            <Sections/>
        </div>
    )
}
export default Large